import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" alt="An image of a checkbox group showing two selections" src="https://user-images.githubusercontent.com/586552/205333350-f767c1ee-61b9-49e8-963d-0fad537749e9.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use checkbox group to allow users to select multiple items from a list of individual items, or to mark one individual item as selected.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Put checkboxes in a logical order`}</li>
      <li parentName="ul">{`If users are only allowed to select a single option, consider using a `}<a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`radio group`}</a>{` instead`}</li>
      <li parentName="ul">{`Each checkbox's state should be independent from other checkboxes in the group. For example: checking one checkbox should not check or disable any other checkboxes`}</li>
    </ul>
    <h2>{`Anatomy`}</h2>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/224140255-57987086-f2b3-47be-9ee7-99af26ea6846.png" />
    <p><strong parentName="p">{`Label:`}</strong>{` A title that labels the group of options and helps users understand the relationship between the options in the group.`}</p>
    <p><strong parentName="p">{`Required indicator:`}</strong>{` Indicates that a selection is required.`}</p>
    <p><strong parentName="p">{`Caption:`}</strong>{` May be used to provide additional context about the checkbox group when the label alone is not sufficient`}</p>
    <p><strong parentName="p">{`Options:`}</strong>{` A list of options represented using `}<a parentName="p" {...{
        "href": "/components/checkbox"
      }}>{`checkboxes`}</a>{`.`}</p>
    <p><strong parentName="p">{`Validation message:`}</strong>{` A message explaining why the selection failed validation. See the `}<a parentName="p" {...{
        "href": "../ui-patterns/forms/overview#validation"
      }}>{`form pattern documentation`}</a>{` for more information on form validation patterns.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`Once a user checks an option from a checkbox group, the result should not be saved or applied until the user has explicitly submitted their input using a "save" or "submit" button. See the `}<a parentName="p" {...{
        "href": "../ui-patterns/saving"
      }}>{`saving pattern guidelines`}</a>{` for more information.
Alternative: you can use a `}<a parentName="p" {...{
        "href": "/components/toggle-switch"
      }}>{`toggle switch`}</a>{` to immediately toggle something on and off as long as it's not in the context of a bigger form that applies other input values when submited`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="CheckboxGroup" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/forms/overview"
        }}>{`Forms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/form-control"
        }}>{`Form control`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/checkbox"
        }}>{`Checkbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio"
        }}>{`Radio`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`Radio group`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      